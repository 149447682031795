@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Calson";
  src:
    local("Calson"),
    url("/src/fonts/Calson/LibreCaslonCondensed-Regular.otf") format("opentype") tech(color-COLRv1),
    url("/src/fonts/Calson/LibreCaslonCondensed-Regular.otf") format("opentype"),
    url("/src/fonts/Calson/LibreCaslonCondensed-Regular.woff2") format("woff2"),
}

.float {
  animation: floating 6s ease-in-out infinite;
}

@keyframes floating {
  0% {
      transform: translateY(0%) rotate(0deg);
  }
  50% {
      transform: translateY(-5%) rotate(5deg);
  }
  100% {
      transform: translateY(0%) rotate(0deg);
  }
}

.screenshot.active img {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.screenshot.inactive img {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}